<template>
    <div class="register">
        <div class="box-card">
            <div class="register-title">单位用户注册</div>
            <div class="register-account">
                <el-form :model="registerForm" :rules="registerRules" ref="registerRef" label-width="140px" label-position="right" show-message>
                    <el-form-item label="是否预算单位" style="text-align: left;">
                        <el-radio-group v-model="registerForm.is_budget"  @change="isBudgetChange">
                            <el-radio :label="2">否</el-radio>
                            <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="registerForm.is_budget == 1">
                        <el-form-item prop="account" label="预算单位账号" >
                            <el-input v-model.trim="registerForm.account" placeholder="请输入预算单位账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="yspassword" label="密码" >
                            <el-input type="password" v-model.trim="registerForm.yspassword" autocomplete="off" placeholder="请输入登录密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="vercode" label="验证码" >
                            <el-input v-model.trim="registerForm.vercode" autocomplete="off" placeholder="请输入验证码" maxlength="4">
                                <template slot="append">
                                    <img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </template>
                    <template v-if="registerForm.is_budget == 2">
                        <el-form-item label="名称" prop="org_name">
                            <el-input v-model.trim="registerForm.org_name" placeholder="请输入单位名称"></el-input></el-form-item>
                        <!--<el-form-item class="regionSelect" label="单位类型" prop="org_type">
                            <el-select v-model.trim="registerForm.org_type" placeholder="请选择单位类型" style="width:100%">
                                <el-option
                                    v-for="(item,index) in companytype"
                                    :key="index"
                                    :label="item"
                                    :value="index">
                                </el-option>
                            </el-select>
                        </el-form-item>-->
                        <el-form-item label="统一社会信用代码" prop="social_credit_code">
                            <el-input v-model.trim="registerForm.social_credit_code" placeholder="请输入统一社会信用代码"></el-input></el-form-item>
                        <div class="regionSelect">
                            <el-form-item label="单位所在地" prop="region">
                                <el-select v-model="province" placeholder="请选择省" @change="provinceChange">
                                    <el-option
                                        v-for="item in provinceOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-model="city" placeholder="请选择市" @change="cityChange">
                                    <el-option
                                        v-for="item in cityOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-model="area" placeholder="请选择区县">
                                    <el-option
                                        v-for="item in areaOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <el-form-item label="详细地址" prop="address">
                            <el-input v-model.trim="registerForm.address" placeholder="请输入详细地址"></el-input>
                        </el-form-item>
                        <el-form-item label="经办人姓名" prop="admin_name">
                            <el-input v-model.trim="registerForm.admin_name" placeholder="请输入经办人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="经办人手机号" prop="mobile">
                            <el-input v-model.trim="registerForm.mobile" placeholder="请输入经办人手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="图形验证码" v-show="showImgVerifyCode">
                            <el-input v-model.trim="captcha.captcha_code" placeholder="请输入图形验证码" maxlength="4">
                                <template slot="append">
                                    <img :src="captcha.img" mode class="captcha" @click="getCode" />
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="手机动态码" prop="code">
                            <el-input v-model="registerForm.code" placeholder="请输入短信验证码">
                                <div style="margin: 0;" slot="append">
                                    <span class="verification" v-if="showTime" @click="openCode">获取验证码</span>
                                    <span v-else>{{yzmTime}}秒后重试</span>
                                </div>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="registerForm.password" placeholder="请输入密码" type="password"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="password2">
                            <el-input v-model="registerForm.password2" placeholder="请输入确认密码" type="password"></el-input>
                        </el-form-item>
    <!--                <el-form-item label="经办人身份证号" prop="admin_card_no"><el-input v-model="registerForm.admin_card_no" placeholder="请输入经办人身份证号"></el-input></el-form-item>
                        <el-form-item label="单位授权书" prop="authorization_letter" style="text-align: left">
                            <el-upload
                                class="avatar-uploader"
                                accept=".docx,.doc"
                                :action="uploadActionUrl"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                                :data="{type:4}">
                                <div v-if="registerForm.authorization_letter" style="display: inline-block"><i class="el-icon-document"></i>{{registerForm.authorization_letter_name}}</div>
                                <el-button style="width: auto;margin-top: 0" v-else size="small" type="primary">点击上传</el-button>
                                <el-tag @click.stop="auth_template" style="margin-left: 10px;" v-if="template_is"><i class="el-icon-download"></i> 下载授权书模板</el-tag>
                            </el-upload>
                            <div style="color: red;font-size: 10px;line-height: 24px;">请上传doc、docx格式文件</div>
                        </el-form-item>-->
                    </template>
                </el-form>
                <div class="xy" @click="check"  v-if="registerForm.is_budget == 2">
                    <div class="xy-wrap">
                        <div class="iconfont" :class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"></div>
                        <div class="content">
                            阅读并同意
                            <b @click.stop="getAggrementCompany" v-if="agreement.title">《{{agreement.title}}》</b>
                            <b @click.stop="getAggrementPrivacy" v-if="agreementPrivacy.title">《{{agreementPrivacy.title}}》</b>
                            <b @click.stop="getAggrementSafegus" v-if="agreementSafegus.title">《{{ agreementSafegus.title }}》</b>
                        </div>
                    </div>
                </div>
                <el-button v-if="registerForm.is_budget == 2" @click="register">立即注册</el-button>
                <el-button v-if="registerForm.is_budget == 1" @click="accountLogin">立即注册</el-button>
            </div>
            <el-row>
                <el-col :span="24">
                    <div class="bg-purple-light">
                        <div class="toLogin" @click="toLogin">已有账号，立即登录</div>
                        <i class="iconfont iconarrow-right"></i>
                    </div>
                </el-col>
            </el-row>
            <el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose" :lock-scroll="false" center>
                <div v-html="agreement.content" class="xyContent"></div>
            </el-dialog>
            <el-dialog :title="agreementPrivacy.title" :visible.sync="aggrementPrivacyVisible" width="60%" :before-close="aggrementClosePrivacy" :lock-scroll="false" center>
                <div v-html="agreementPrivacy.content" class="xyContent"></div>
            </el-dialog>
            <el-dialog :title="agreementSafegus.title" :visible.sync="aggrementSafegus" width="60%" :before-close="aggrementCloseSafegus" :lock-scroll="false" center>
                <div v-html="agreementSafegus.content" class="xyContent"></div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {
        getRegisiterAggrementCompany,
        getRegisiterAggrementPrivacy,
        getRegisiterAggrementSafegus,
        register,
        registerAddressLists,
        registerConfig,
        registerMobileCode
    } from "@/api/auth/register"
    import Utli from '@/utils/util.js'
    import { captcha } from '@/api/website';
    import Config from "@/utils/config"
    import { memberTypeList } from "@/api/member"
    import {loginCaptcha} from "@/api/auth/login";
    import {Base64} from "js-base64";
    import Cookies from "js-cookie";
    import {checkCookies} from "@/components/cookies_fun";
    import CryptoJS from "crypto-js";


    export default {
        name: 'register',
        components: {},
        data() {
            var usernameValidata = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号！'));
                } else if (!Utli.checkPhone(value)) {
                    callback(new Error('请输入正确的手机号码!'));
                } else {
                    callback();
                }
            };
            var checkPassValidata = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.registerForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            let self = this;
            var passwordValidata = function(rule, value, callback) {
                let regConfig = self.registerConfig;
                if (!value) {
                    return callback(new Error('请输入密码'));
                } else {
                    if (regConfig.pwd_len > 0) {
                        if (value.length < regConfig.pwd_len) {
                            return callback(new Error('密码长度不能小于' + regConfig.pwd_len + '位'));
                        } else {
                            callback();
                        }
                    } else {
                        if (regConfig.pwd_complexity != '') {
                            let passwordErrorMsg = '密码需包含',
                                reg = '';
                            if (regConfig.pwd_complexity.indexOf('number') != -1) {
                                reg += '(?=.*?[0-9])';
                                passwordErrorMsg += '数字';
                            } else if (regConfig.pwd_complexity.indexOf('letter') != -1) {
                                reg += '(?=.*?[a-z])';
                                passwordErrorMsg += '、小写字母';
                            } else if (regConfig.pwd_complexity.indexOf('upper_case') != -1) {
                                reg += '(?=.*?[A-Z])';
                                passwordErrorMsg += '、大写字母';
                            } else if (regConfig.pwd_complexity.indexOf('symbol') != -1) {
                                reg += '(?=.*?[#?!@$%^&*-])';
                                passwordErrorMsg += '、特殊字符';
                            } else {
                                reg += '';
                                passwordErrorMsg += '';
                            }

                            if (reg.test(value)) {
                                return callback(new Error(passwordErrorMsg));
                            } else {
                                callback();
                            }
                        }
                    }
                }
            };
            return {
                uploadActionUrl: Config.baseUrl + '/api/upload/file',
                registerForm: {
                    //rank_type: '3', //注册类型 3是企业注册 1 是普通注册
                    rank_type: '2', //注册类型：1 个人，2 单位
                    is_budget: 2, //是否预算单位：1 是，2 否
                    org_name: '',// 单位名称
                    org_type: '',// 注册单位类型
                    social_credit_code: '', // 统一社会信用码
                    admin_name: '', // 经办人姓名,同时作为用户真实姓名
                    mobile: '', // 经办人手机号,手机号,同时也作为登录(用户)账号和经办人手机号
                    code: '', // 短信验证码
                    password: "", // 密码
                    password2: "", // 再次输入密码
                    key: "", // 短信验证码key
                    authorization_letter: "", // 单位授权书图片url
                    authorization_letter_name:"",
                    address: "", // 详细地址
                    admin_card_no: "", // 经办人身份证
                    region: '',
                    account:'',
                    yspassword:'',
                    vercode:'',
                },
                companytype: [],
                yzmTime: 60,
                showTime: true,
                registerRules: {
                    mobile: [{ required: true, validator: usernameValidata,  trigger: 'blur' }],
                    password: [
                        {
                            required: true,
                            validator: passwordValidata,
                            trigger: 'blur'
                        }
                    ],
                    password2: [{ required: true, validator: checkPassValidata, trigger: 'blur' }],
                    code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
                    username: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
                    org_type: [{ required: true, message: '请选择注册类型', trigger: 'blur' }],
                    org_name: [
                        { required: true, message: "单位名称", trigger: "blur" },
                        { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" }
                    ],
                    admin_name: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' }],
                    admin_card_no: [{ required: true, message: '请输入经办人身份证号', trigger: 'blur' }],
                    social_credit_code: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
                    // authorization_letter: [{ required: true, message: '请上传单位授权书', trigger: 'blur' }],
                    address: [
                        { required: true, message: '请输入详细地址', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }],
                    region: [{ required: true, message: '请选择区域', trigger: 'blur' }],
                    account: [{
                        required: true,
                        message: "请输入预算单位账号",
                        trigger: "blur"
                    }],
                    yspassword: [{
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    }],
                    vercode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    }]
                },
                ischecked: false,
                agreement: {},
                aggrementVisible: false,
                agreementPrivacy: {},
                aggrementPrivacyVisible: false,
                agreementSafegus: {},
                aggrementSafegus: false,
                captcha: {
                    // 验证码
                    id  : '',
                    captcha_code :'',
                    img     : ''
                },
                registerConfig: {},
                province: '',
                city: '',
                area: '',
                provinceOptions: [],
                cityOptions: [],
                areaOptions: [],
                imageUrl: '',
                template_is : 0,
                showImgVerifyCode : false,
                isSub: false, // 提交防重复
            };
        },
        created() {
            this.getCode();
            this.regisiterAggrementCompany(); //单位协议
            this.regisiterAggrementPrivacy(); //隐私协议
            this.regisiterAggrementSafegus() //隐私协议
            this.getRegisterConfig();
            this.provinceData(); // 获取地市
        },
        methods: {
            isBudgetChange(value){
                if(value == 1){
                    this.getCaptcha()
                }
                this.$nextTick(() => {
                    this.$refs.registerRef.resetFields();
                })
            },
            /**
             * 获取验证码
             */
            getCaptcha () {
                let that = this;
                this.captcha.id = this.randomString(32);

                loginCaptcha({
                    id: this.captcha.id,
                    is_m: 0,
                    rnt: Math.random()
                })
                    .then(res => {
                        if(res.code == 10000){
                            that.captcha.img = res.result.image;
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            randomString (e) {//产生随机数
                e = e || 32;
                var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
                    a = t.length,
                    n = "";
                for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
                return Base64.encode(n);
            },
            encrypt (word, keyM) {//加密函数
                var key = CryptoJS.enc.Utf8.parse(keyM);
                var srcs = CryptoJS.enc.Utf8.parse(word);
                var encrypted = CryptoJS.AES.encrypt(srcs, key, {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                });
                return encrypted.toString();
            },
            /**
             * 账号登录
             */
            accountLogin () {
                this.$refs.registerRef.validate(valid => {
                    if (valid) {
                        var keyM = "1229E9D70BB43ABB"; //使用固定的key，防止压测时，因session超时报错
                        var encryptData = this.encrypt(this.registerForm.account, keyM);
                        var username = Base64.encode(encryptData);
                        encryptData = this.encrypt(this.registerForm.yspassword, keyM);
                        var password = Base64.encode(encryptData);
                        var data = {
                            username: this.registerForm.account,
                            password: this.registerForm.yspassword,
                            uaccount: username,
                            upassword: password,
                            captcha_id: this.captcha.id,
                            captcha_code: this.registerForm.vercode
                        }
                        if (this.isSub) return
                        this.isSub = true
                        this.$store
                            .dispatch("member/login", data)
                            .then(res => {
                                if (res.code >= 0) {
                                    this.$message({
                                        message: "注册成功！",
                                        type: "success"
                                    })
                                    this.$router.push('/member/index');
                                } else {
                                    this.isSub = false
                                    this.getCaptcha()
                                    this.$message({
                                        message: res.message,
                                        type: "warning"
                                    })
                                }
                            })
                            .catch(err => {
                                this.isSub = false
                                this.$message.error(err.message)
                                this.getCaptcha()
                            })
                    }
                })
            },
            // 省选择事件
            async provinceChange(value) {
                this.city = ''
                this.cityOptions = []
                this.area = ''
                this.areaOptions = []
                this.cityOptions = await this.registerAddressListsData({id: value,pid: value})
            },
            // 市选择事件
            async cityChange(value) {
                this.area = ''
                this.areaOptions = []
                this.areaOptions = await this.registerAddressListsData({id: value,pid: value})
            },
            async provinceData() {
                this.provinceOptions = await this.registerAddressListsData()
            },
            // 获取地址
            async registerAddressListsData(city = {}) {
                let type = {...city,
                    app_type: "pc",
                    app_type_name: "电脑端",
                }
                return new Promise(resolve => {
                    registerAddressLists(type).then(res =>{
                        let data = res.data
                        resolve(data)
                    })
                })

            },
            handleAvatarSuccess(res, file) {
                let data = res.data
                this.registerForm.authorization_letter      = data.path;
                this.registerForm.authorization_letter_name = data.file_name;
                if(res.code<=0){
                    this.$message.error(res.message);
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg' || 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 10;

                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG ,png格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                }
                return isJPG && isLt2M;
            },
            // 发送验证码
            openCode() {
                if (!this.registerForm.mobile) {
                    this.$message.warning('请输入经办人手机号')
                    return
                } else if (!Utli.checkPhone(this.registerForm.mobile)) {
                    this.$message.warning('请输入正确的手机！')
                    return
                }
                if (!this.captcha.captcha_code) {
                    this.showImgVerifyCode = true;
                    this.$message.warning('请输入图形验证码')
                    return
                }
                let data = {
                    app_type: 'h5',
                    app_type_name: 'H5',
                    web_city: '0',
                    mobile: this.registerForm.mobile,
                    captcha_id: this.captcha.id,//'51f17a461ccafc7564ead6043f8da837',
                    captcha_code: this.captcha.captcha_code,//'8332',
                }
                registerMobileCode(data).then(res => {
                    let data = res.data
                    this.showTime = false
                    this.registerForm.key = data.key
                    let time = setInterval(() => {
                        this.yzmTime = this.yzmTime - 1
                        if (this.yzmTime <= 0) {
                            clearInterval(time)
                            this.yzmTime = 60
                            this.showTime = true
                        }
                    },1000)
                }).catch(e =>{
                    this.$message.error(e.message)
                    this.getCode();
                    //if(e.error_code=='CAPTCHA_FAILURE')this.getCode();
                })
            },
            check() {
                this.ischecked = !this.ischecked;
            },
            toLogin() {
                this.$router.push('/login');
            },
            //  获取注册配置
            getRegisterConfig() {
                registerConfig()
                    .then(res => {
                        if (res.code >= 0) {
                            this.companytype    = res.data.org_type;
                            this.template_is    = res.data.template_is;
                            this.registerConfig = res.data.value;
                            if (this.registerConfig.register == '') {
                                this.$message({
                                    message: '平台未启用注册',
                                    type: 'warning',
                                    duration: 2000,
                                    onClose: () => {
                                        this.$router.push('/');
                                    }
                                });
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    });
            },
            // 注册
            register() {
                if (this.province == '' || this.city == '' || this.area == '') {
                    this.registerForm.region = ''
                } else {
                    this.registerForm.region = 1
                }
                this.$refs.registerRef.validate(valid => {
                    if (valid) {
                        if (!this.ischecked) {
                            return this.$message({
                                message: '请先阅读协议并勾选',
                                type: 'warning'
                            });
                        }
                        let province_name = ''
                        let city_name = ''
                        let district_name = ''
                        this.provinceOptions.map((item) => {
                            if (this.province == item.id) {
                                province_name = item.name
                            }
                        })
                        this.cityOptions.map((item) => {
                            if (this.city == item.id) {
                                city_name = item.name
                            }
                        })
                        this.areaOptions.map((item) => {
                            if (this.area == item.id) {
                                district_name = item.name
                            }
                        })

                        var data = {
                            app_type: 'pc', // app类型
                            app_type_name: '电脑端', // app类型名
                            rank_type: '2', // 注册类型: 1 个人, 2 单位
                            mobile: this.registerForm.mobile, // 手机号 //同时也作为登录(用户)账号和经办人手机号
                            password: this.registerForm.password, // 密码
                            password2: this.registerForm.password2, // 再次输入密码
                            code: this.registerForm.code, // 短信验证码
                            key: this.registerForm.key, // 短信验证码key
                            province_id: this.province, // 省id
                            province_name: province_name, //省
                            city: this.city, // 市id
                            city_name: city_name, // 市
                            district: this.area, // 区县id
                            district_name: district_name, // 区县
                            admin_name: this.registerForm.admin_name, // 经办人 //企业注册用,同时作为用户真实姓名
                            social_credit_code: this.registerForm.social_credit_code, // 统一社会信用码
                            org_name: this.registerForm.org_name, // 单位名称
                            org_type: this.registerForm.org_type, // 企业类型
                            authorization_letter: this.registerForm.authorization_letter, // 单位授权书图片url
                            address: this.registerForm.address, // 详细地址
                            admin_card_no: this.registerForm.admin_card_no, // 经办人身份证
                        };
                        this.$store
                            .dispatch('member/register_token', data)
                            .then(res => {
                                if (res.code >= 0) {
                                    this.$router.push('/member/index');
                                }
                            })
                            .catch(err => {
                                this.$message.error(err.message);
                                this.getCode();
                            });
                    } else {
                        return false;
                    }
                });
            },
            aggrementClose() {
                this.aggrementVisible = false;
            },
            getAggrementCompany() {
                this.aggrementVisible = true;
            },
            // 获取协议
            regisiterAggrementCompany() {
                getRegisiterAggrementCompany()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreement = res.data;
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    });
            },
            getAggrementPrivacy() {
                this.aggrementPrivacyVisible = true;
            },
            aggrementClosePrivacy() {
                this.aggrementPrivacyVisible = false;
            },
            // 获取协议
            regisiterAggrementPrivacy() {
                getRegisiterAggrementPrivacy()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreementPrivacy = res.data;
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    });
            },
            // 维权中心保障协议
            getAggrementSafegus() {
                this.aggrementSafegus = true;
            },
            aggrementCloseSafegus() {
                this.aggrementSafegus = false;
            },
            // 获取维权中心保障协议 todo
            regisiterAggrementSafegus() {
                getRegisiterAggrementSafegus()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreementSafegus = res.data
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            // 获取验证码
            getCode() {
                captcha(this.captcha)
                    .then(res => {
                        if (res.code >= 0) {
                            res.data.captcha_code = res.data.captcha_code || '';
                            this.captcha = res.data;
                            this.captcha.img = this.captcha.img.replace(/\r\n/g, '');
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message);
                    });
            },
            //下载授权书模板
            auth_template(){
                 window.open(Config.baseUrl+'/api/register/auth_template');
            }
        }
    };
</script>
<style lang="scss" scoped type="text/scss">
  .regionSelect{
    text-align: left;
    ::v-deep .el-form-item__content{
      display: flex;
    }
    ::v-deep .el-button{
      margin-top: 0 !important;
    }
  }
  .verification{
    cursor: pointer;
  }
  .register {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 40px 0;
  }
  .box-card {
    width: 700px;
    margin: 0 auto;
    display: flex;
    background-color: #ffffff;
    padding: 0 30px 30px 30px;
    flex-direction: column;

    .register-title {
      border-bottom: 1px solid #f1f1f1;
      text-align: left;
      margin-bottom: 20px;
      font-size: 16px;
      color: $base-color;
      padding: 10px 0;
    }
    .register-account {
      width: 100%;
      text-align: center;
    }
    .code {
      width: 80%;
      text-align: left;
    }
    .el-form {
      margin: 0 30px;
      .captcha {
        vertical-align: top;
        max-width: inherit;
        max-height: 38px;
        line-height: 38px;
        cursor: pointer;
      }
    }
    .xyContent {
      height: 600px;
      overflow-y: scroll;
    }
    .xy {
      margin-left: 110px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      margin-right: 30px;
      .toLogin {
        cursor: pointer;
      }
      .xy-wrap {
        display: flex;
        align-items: center;
        font-size: $ns-font-size-base;
        cursor: pointer;
        .iconfont {
          display: flex;
          align-content: center;
        }
        .content {
          margin-left: 3px;
          b {
            color: $base-color;
          }
        }
      }
      .iconxuanze-duoxuan {
        color: $base-color;
      }
    }
    .el-button {
      margin-top: 20px;
      background-color: $base-color;
      color: #ffffff;
      width: 50%;
    }
  }
  
    .bg-purple-light {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            background-color: $base-color;
            color: #ffffff;
            font-size: 12px;
            margin-left: 8px;
        }
    }
</style>
